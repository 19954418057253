import React from 'react';
import './App.css';
import Comingsoon from './components/Comingsoon/Comingsoon';
import {BrowserRouter , Routes , Route} from 'react-router-dom';
import Donate from './components/Donate'

function App() {

  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Comingsoon />} />
        <Route path="/donate" element={<Donate />} />
      </Routes>
   </BrowserRouter>

  );

}

export default App;
