import React from 'react'
import { useEffect } from "react";

function Donate(){
    useEffect(() => {
        const timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace('https://give.deenfund.com/masjid-baitul-aman');
        }, 1);
    
        return () => clearTimeout(timeout);
      }, []);
    
      return (
        <div>
          <h1>Redirecting...</h1>
        </div>
      );
}

export default Donate ; 